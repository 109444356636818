<template>
  <div>
    <ModalTemplate :showModal="showModal" width="1000px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <p class="averta-bold label-18 ml-3">Atur Daftar {{ title }}</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <div class="body-modal">
            <div class="table-unit-price border-1-grey-13" v-if="!isLoading">
              <!-- HEADER -->
              <div
                class="is-flex color-white border-radius-modal-header"
                :style="{ width: '100%' }"
              >
                <div
                  class="sticky-all column-header left bg-dark-grey-5"
                  :style="{
                    width: '29%',
                    height: `${S.HEIGHT_COLUMN_HEADER}px`,
                    borderLeft: 0
                  }"
                >
                  <p class="averta-bold">PEKERJAAN</p>
                </div>
                <div
                  v-for="(item, index) in titleHeader"
                  :key="index"
                  class="column-header bg-dark-grey-5"
                  :style="{ width: item.width, height: `${S.HEIGHT_COLUMN_HEADER}px` }"
                >
                  <p class="averta-bold has-text-centered">{{ item.title }}</p>
                </div>
              </div>

              <!-- STICKY NOTE -->
              <div
                class="sticky-left flex-center-vertical border-bottom-1-grey-13 px-4"
                :style="{
                  height: `${S.HEIGHT_STICKY_TITLE_SECTION}px`,
                  width: '100%',
                  background
                }"
              >
                <p class="averta-black label-16 title-section">{{ title }}</p>
              </div>

              <!-- BODY -->
              <div class="is-relative">
                <div class="sticky-top z-index-20">
                  <div class="is-flex" :style="{ width: '100%' }">
                    <div
                      class="sticky-all column-header left body"
                      :style="{
                        width: '29%',
                        height: `${S.HEIGHT_INIT_COLUMN}px`,
                        borderLeft: 0
                      }"
                    >
                      <div class="c-w-100">
                        <InputAutocompleteMaterials
                          v-if="!isWorkerFees"
                          :code="code"
                          :hideBorder="true"
                          @selectedUnit="handleSelectMaterial($event)"
                        />
                        <InputAutocompleteUpah
                          v-else
                          :value="{ name: name, fee_type: feeType }"
                          :hideBorder="true"
                          :unsetMaxWidth="true"
                          :bold="true"
                          @selected="handleSelectUpah($event)"
                        />
                      </div>
                    </div>

                    <!-- Harga Per Satuan -->
                    <div
                      class="column-header body"
                      :style="{ width: '17%', height: `${S.HEIGHT_INIT_COLUMN}px` }"
                    >
                      <div
                        class="c-w-100 flex-center-vertical is-justify-content-space-between averta-bold"
                      >
                        <p class="mr-1">Rp</p>
                        <!-- :value="price || 0" -->
                        <InputPrice
                          :value="price"
                          :hideFormat="true"
                          :hideBorder="true"
                          :disabled="isDisabled"
                          :className="isDisabled ? 'averta-bold' : 'hover-border-1-grey-2'"
                          @change="handleChangeInput($event, 'price')"
                        />
                      </div>
                    </div>

                    <!-- Volume -->
                    <div
                      class="column-header body"
                      :style="{ width: '12%', height: `${S.HEIGHT_INIT_COLUMN}px` }"
                    >
                      <input
                        v-model="volume"
                        type="number"
                        class="averta-bold has-text-centered outline-none c-w-100 input-volume-unit-price"
                        :disabled="isDisabled"
                        placeholder="0"
                        :class="[{ hover: !isDisabled }]"
                        @input="(e) => handleChangeInput(e.target.value, 'volume')"
                      />
                    </div>

                    <!-- Satuan -->
                    <div
                      class="column-header body p-0"
                      :style="{ width: '12%', height: `${S.HEIGHT_INIT_COLUMN}px` }"
                    >
                      <div class="flex-center">
                        <InputAutocompleteSatuan
                          :value="{ name: unit.name, id: unit.id }"
                          :hideBorder="true"
                          :unsetMaxWidth="true"
                          :bold="true"
                          @selectedUnit="handleSelectUnit($event)"
                        />
                      </div>
                    </div>

                    <!-- Material -->
                    <div
                      class="column-header body"
                      :class="[isWorkerFees ? 'bg-lightgray-8' : '']"
                      :style="{ width: '15%', height: `${S.HEIGHT_INIT_COLUMN}px` }"
                    >
                      <div
                        v-if="!isWorkerFees"
                        class="flex-center-vertical c-w-100 is-justify-content-space-between averta-bold"
                      >
                        <p>Rp</p>
                        <p>
                          {{
                            formatThousand(material_price ? material_price : (volume || 0) * price)
                          }}
                        </p>
                      </div>
                    </div>

                    <!-- Upah -->
                    <div
                      class="column-header body worker-price"
                      :class="[!isWorkerFees ? 'bg-lightgray-8' : '']"
                      :style="{
                        width: '15.01%',
                        height: `${S.HEIGHT_INIT_COLUMN}px`
                      }"
                    >
                      <div
                        v-if="isWorkerFees"
                        class="flex-center-vertical c-w-100 is-justify-content-space-between averta-bold"
                      >
                        <p>Rp</p>
                        <p>{{ formatThousand(worker_price) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-modal">
            <ButtonApp height="40px" :isDisabled="disabledSubmit" @click="handleSubmit">
              <div class="px-1 flex-center">
                <CheckIcon color="#fff" />
                <p class="averta-bold ml-1">Simpan</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency, removeComma, alertErrorMessage, arrayToName } from '@/helpers'

export default {
  name: 'ModalComponentItem',
  components: {
    CheckIcon: () => import('@/components/icons/CheckIcon.vue'),
    ModalTemplate: () => import('@/components/modal/ModalTemplate.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    InputAutocompleteSatuan: () => import('@/components/input/InputAutocompleteSatuan.vue'),
    InputAutocompleteMaterials: () => import('@/components/input/InputAutocompleteMaterials.vue'),
    InputAutocompleteUpah: () => import('@/components/input/InputAutocompleteUpah.vue'),
    InputPrice: () => import('@/components/input/InputPrice.vue')
  },
  props: {
    code: {
      type: String
    },
    showModalProps: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      showModal: this.showModalProps,
      title: '',
      titleMaterials: '',
      background: '',

      materials: [],
      work_tools: [],
      auxiliary_tools: [],
      worker_fees: [],
      initListMaterials: [],

      name: '',
      selected: [],
      price: '',
      volume: null,
      unit: {},
      material_price: null,
      worker_price: null,
      note: '',
      feeType: null,

      titleHeader: [
        {
          title: 'HARGA PER SATUAN',
          width: '17%'
        },
        {
          title: 'VOLUME',
          width: '12%'
        },
        {
          title: 'SATUAN',
          width: '12%'
        },
        {
          title: 'MATERIAL',
          width: '15%'
        },
        {
          title: 'UPAH',
          width: '15.01%'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      S: 'unitPrice/staticUnitPrice',
      selectedMaterials: 'unitPrice/getSelectedMaterials',
      formDetail: 'unitPrice/getFormDetailUnitPrice',
      getUnitPrice: 'unitPrice/getUnitPrice'
    }),
    disabledSubmit () {
      return (
        !this.price ||
        this.price === '0' ||
        (!this.isWorkerFees && !this.volume) ||
        !this.unit.id ||
        (this.isMaterial && this.selectedMaterials.length < 1) ||
        (this.isWorkTool && !this.name) ||
        (this.isWorkerFees && !this.feeType)
      )
    },
    isDisabled () {
      return this.name === ''
    },
    isMaterial () {
      return this.code === 'add_materials' || this.code === 'edit_materials'
    },
    isWorkTool () {
      return this.code === 'add_work_tools' || this.code === 'edit_work_tools'
    },
    isAuxiliaryTool () {
      return this.code === 'add_auxiliary_tools' || this.code === 'edit_auxiliary_tools'
    },
    isWorkerFees () {
      return this.code === 'add_worker_fees' || this.code === 'edit_worker_fees'
    },
    titleInputName () {
      switch (this.code) {
        case 'add_materials':
        case 'edit_materials':
          return 'Nama Material'

        case 'add_work_tools':
        case 'edit_work_tools':
          return 'Nama Alat Kerja'

        case 'add_auxiliary_tools':
        case 'edit_auxiliary_tools':
          return 'Nama Alat Bantu'

        case 'add_worker_fees':
        case 'edit_worker_fees':
          return 'Upah Kerja'

        default:
          return 'Nama'
      }
    }
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    closeModal () {
      this.$emit('closeModal')
      this.resetStore()
    },
    resetStore () {
      this.name = null
      this.price = null
      this.volume = null
      this.material_price = null
      this.worker_price = null
      this.unit = {
        id: null,
        name: null
      }
      this.$store.commit('unitPrice/setSelectedMaterials', [])
      this.$store.commit('unitPrice/setFormDetailUnitPrice', 'reset')
    },
    handleChangeInput (value, type) {
      if (type === 'price') {
        this.price = value ? formatCurrency(value, true) : 0
      }

      const volume = type === 'volume' ? JSON.parse(JSON.stringify(value)) : this.volume || 0
      if (this.isWorkerFees) {
        this.worker_price = volume * removeComma(this.price || 0)
      } else {
        this.material_price = volume * removeComma(this.price || 0)
      }
    },
    handleSelectUnit (option) {
      if (option === 'empty') {
        this.unit = {
          id: null,
          name: null
        }
      } else {
        this.unit = option
      }
    },
    handleSelectUpah (event) {
      this.name = event.name
      this.feeType = event.fee_type
    },
    handleSelectMaterial (data) {
      if (data && data.length > 0) {
        this.name = arrayToName(data)
        this.selected = data

        let tempPrice = 0
        // let tempVolume = 0
        for (const item of data) {
          tempPrice += item.price || 0
          // tempVolume += item.volume || 0
        }

        this.price = this.formatThousand(tempPrice)

        const findUnit = data.find(item => item.unit_id)
        this.unit = {
          id: findUnit.unit_id,
          name: findUnit.unit_name
        }
        // this.volume = tempVolume

        if (!this.isWorkerFees) {
          this.material_price = this.volume * removeComma(this.price)
        } else {
          this.worker_price = this.volume * removeComma(this.price)
        }
      } else {
        this.name = ''
        this.selected = []
        this.price = null
        this.volume = null
        this.material_price = 0
        this.worker_price = 0
      }
    },
    handleSubmit () {
      const body = {
        ...this.formDetail,
        index: this.formDetail ? this.formDetail.index : null,
        volume: this.volume,
        price: removeComma(this.price),
        unit_id: this.unit.id,
        unit_name: this.unit.name,
        unit: this.unit,
        code: this.code,
        note: ''
      }

      if (this.isWorkerFees) {
        body.worker_price = this.volume * removeComma(this.price)
      } else {
        body.material_price = this.volume * removeComma(this.price)
      }

      let payload = null
      if (this.isMaterial) {
        payload = {
          ...body,
          name: this.titleMaterials,
          selected: this.selectedMaterials,
          isEdit: this.code === 'edit_materials'
        }
      } else if (this.isWorkTool) {
        payload = {
          ...body,
          name: this.name,
          selected: this.selectedMaterials,
          isEdit: this.code === 'edit_work_tools'
        }
      } else if (this.isAuxiliaryTool) {
        payload = {
          ...body,
          name: this.name,
          selected: this.selectedMaterials,
          isEdit: this.code === 'edit_auxiliary_tools'
        }
      } else if (this.isWorkerFees) {
        payload = {
          ...body,
          name: this.feeType === 1 || this.feeType === '1' ? 'Upah Tukang' : 'Upah Kenek',
          fee_type: this.feeType,
          isEdit: this.code === 'edit_worker_fees'
        }
      }

      this.$store.commit('unitPrice/setDetailUnitPrice', payload)
      this.closeModal()
      setTimeout(() => {
        this.resetStore()
      }, 300)
    },
    getSelectedMaterial (title) {
      this.isLoading = true

      const payload = {
        url: this.url,
        // disable_paginate: true
        limit: 1000,
        page: this.page
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then((response) => {
          const res = response.data.data
          this.initListMaterials = res
          this.isLoading = false

          this.setMaterial(title)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    setMaterial (title) {
      const result = this.initListMaterials
        .filter((item) => title.includes(item.name))
        .map((item) => ({ ...item, checked: true }))

      this.$store.commit('unitPrice/setSelectedMaterials', result)
    }
  },
  mounted () {},
  watch: {
    showModalProps (val) {
      this.showModal = val

      if (this.isMaterial) {
        this.title = 'Material'
        this.background = '#FCE5E6'
      } else if (this.isWorkTool) {
        this.title = 'Alat Kerja'
        this.background = '#E1EEFF'
      } else if (this.isAuxiliaryTool) {
        this.title = 'Alat Bantu'
        this.background = '#E7F8EC'
      } else if (this.isWorkerFees) {
        this.title = 'Upah Kerja'
        this.background = '#FFF3E6'
      } else {
        this.title = ''
      }

      const res = this.formDetail
      if (res && val) {
        if (res.selected && res.selected.length > 0) {
          this.$store.commit('unitPrice/setSelectedMaterials', res.selected)
        }
        this.name = res.name || ''
        if (this.initListMaterials.length > 0) {
          this.setMaterial(this.name)
        } else {
          this.getSelectedMaterial(this.name)
        }
        this.feeType = res.fee_type || null
        this.selected = res.selected && res.selected.length > 0 ? res.selected : []
        this.price = res.price || null
        this.volume = res.volume || null
        this.material_price = res.material_price || null
        this.worker_price = res.worker_price || null
        this.unit.id = res.unit_id || null
        this.unit.name = res.unit_name || null
      }

      // const res = this.getUnitPrice.detail
      // if (res) {
      //   const temp = {
      //     name: '',
      //     volume: '',
      //     price: '',
      //     unit_id: '',
      //     unit_name: '',
      //     material_price: '',
      //     note: ''
      //   }

      //   this.materials =
      //     res.materials.length > 0
      //       ? res.materials
      //       : [
      //         {
      //           ...temp,
      //           code: 'add',
      //           isAdd: true,
      //           index: this.materials.length,
      //           selected: []
      //         }
      //       ]
      //   this.work_tools = res.work_tools || []
      //   this.auxiliary_tools = res.auxiliary_tools || []
      //   this.worker_fees = res.worker_fees || []
      // }
    },
    selectedMaterials: {
      handler (val) {
        if (val) {
          let temp = ''
          if (this.selectedMaterials && this.selectedMaterials.length > 0) {
            this.selectedMaterials.forEach((item, index) => {
              temp += `${item.name}${index === this.selectedMaterials.length - 1 ? '' : ', '}`
            })
          }
          this.titleMaterials = temp
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.wrap-body-modal {
  background: white;
  height: 300px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.body-modal {
  height: 80%;
  padding: 15px;
  position: relative;
}

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 0px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20%;
}

.table-unit-price {
  position: relative;
  width: 100%;
  overflow: visible;
  border-radius: 10px;
  max-height: 77vh;
}

.column-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 10px;
  border-left: 1px solid #fff;

  &.body {
    border-color: #c0c0c0 !important;
    justify-content: flex-start;
  }

  &.left {
    border-right: 6px solid #fff;
    z-index: 4;
  }

  &.right {
    border-left: 4px solid #fff;
    z-index: 4;
  }

  &.worker-price {
    border-bottom-right-radius: 9px;
    overflow: hidden;
  }
}

.title-section {
  position: sticky;
  left: 12px;
}

.body-unit-price {
  min-height: 5em;
}

.border-radius-modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.input-volume-unit-price {
  border: none;
  padding: 8px 0;
}

.input-volume-unit-price:disabled {
  background: white;
}

.input-volume-unit-price.hover:hover,
.input-volume-unit-price.hover:focus {
  border: 1px solid $color-grey-2;
}
</style>

<style>
.cs-autocomplete-satuan-material .autocomplete .dropdown-content {
  max-height: 150px !important;
}

.cs-autocomplete-satuan-material .autocomplete input {
  font-size: 14px !important;
  padding: 19px 10px !important;
}
</style>
